import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';

import Container from 'components/shared/container';
import QuoteIcon from 'icons/quote.inline.svg';

import CircleIcon from './images/circle.inline.svg';
import SlideWithImage from './slide-with-image';
import SlideWithQuote from './slide-with-quote';

// Wrapper
const wrapperClassNames = classNames(
  /* base */ 'relative overflow-hidden bg-gray-1 py-18 pb-6 mt-12',
  /* xl-size */ 'xl:mt-36 xl:py-20 xl:py-24',
  /* lg-size */ 'lg:py-16',
  /* md-size */ 'md:pb-10 md:mt-24'
);

// Content
const contentLeftClassNames = classNames(
  /* base */ 'relative col-start-1 col-end-13',
  /* xl-size */ 'xl:max-w-[696px]',
  /* lg-size */ 'lg:col-start-1 lg:col-end-7 lg:max-w-[444px]',
  /* md-size */ 'md:col-start-3 md:col-end-11'
);

const contentRightClassNames = classNames(
  /* base */ 'col-start-1 col-end-13',
  /* xl-size */ 'xl:ml-0',
  /* lg-size */ 'lg:col-start-7 lg:col-end-13 lg:-ml-2',
  /* md-size */ 'md:col-start-3 md:col-end-11'
);

// Illustration
const circle1ClassNames = classNames(
  /* base */ 'hidden absolute -top-10 -right-10 max-w-[144px] h-auto',
  /* xl-size */ 'xl:-top-16 xl:-right-16 xl:max-w-[224px]',
  /* lg-size */ 'lg:block'
);

// Description
const circle2ClassNames = classNames(
  /* base */ 'hidden absolute -top-10 -left-10 max-w-[112px] h-auto',
  /* xl-size */ 'xl:-top-16 xl:left-20 xl:max-w-full',
  /* lg-size */ 'lg:left-14',
  /* md-size */ 'md:block'
);

// Slider
const dotsWrapperClassNames = classNames(
  /* base */ '-bottom-9 transform',
  /* xl-size */ 'xl:bottom-[-60px]',
  /* lg-size */ 'lg:absolute lg:left-1/2 lg:-translate-x-1/2'
);

const dotsClassNames = classNames(
  /* base */ 'rounded-full h-2 w-2 bg-gray-2 mr-2 cursor-pointer',
  /* xl-size */ 'xl:h-3 xl:w-3 xl:mr-3'
);

// Quote Icon
const quoteIconWrapperClassNames = classNames(
  /* base */ 'absolute z-10 -top-6 right-10 flex items-center justify-center bg-primary-1 h-[52px] w-[52px] rounded-full',
  /* lg-size */ 'lg:hidden'
);

const quoteIconClassNames = classNames(/* base */ 'w-[26px]');

const SliderWithQuote = ({ items }) => {
  const [sliderRefs, setSliderRefs] = useState({ sliderWithImage: null, sliderWithQuote: null });
  const sliderWithImage = useRef();
  const sliderWithQuote = useRef();

  useEffect(() => {
    if (!sliderWithImage.current || !sliderWithQuote.current) {
      return;
    }
    setSliderRefs({
      sliderWithImage: sliderWithImage.current,
      sliderWithQuote: sliderWithQuote.current,
    });
  }, [sliderWithImage, sliderWithQuote]);

  const settingsSliderWithImage = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    appendDots: (dots) => (
      <div>
        <ul className="flex py-8 slick-dots lg:justify-center lg:py-0">{dots}</ul>
      </div>
    ),
    // the styles for the active dot => styles/global.css
    dotsClass: dotsWrapperClassNames,
    customPaging: () => <div className={dotsClassNames} />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const settingsSliderWithQuote = {
    dots: false,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    appendDots: (dots) => (
      <div>
        <ul className="flex py-8 slick-dots lg:justify-center lg:py-0">{dots}</ul>
      </div>
    ),
    // the styles for the active dot => styles/global.css
    dotsClass: dotsWrapperClassNames,
    customPaging: () => <div className={dotsClassNames} />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: true,
        },
      },
    ],
  };

  return (
    <section className={wrapperClassNames}>
      <CircleIcon className={circle1ClassNames} />
      <Container>
        <div className="grid items-center grid-cols-12 md:gap-x-14 lg:gap-x-10">
          <div className={contentLeftClassNames}>
            <CircleIcon className={circle2ClassNames} />
            <div className={quoteIconWrapperClassNames}>
              <QuoteIcon className={quoteIconClassNames} />
            </div>
            <Slider
              asNavFor={sliderRefs.sliderWithQuote}
              ref={sliderWithImage}
              {...settingsSliderWithImage}
            >
              {items.map(({ image: { localFile, altText: imageAltText } }, index) => {
                const image = localFile.childImageSharp;
                return <SlideWithImage image={image} altText={imageAltText} key={index} />;
              })}
            </Slider>
          </div>
          <div className={contentRightClassNames}>
            <Slider
              asNavFor={sliderRefs.sliderWithImage}
              ref={sliderWithQuote}
              {...settingsSliderWithQuote}
            >
              {items.map(({ logo: { localFile, altText: logoAltText }, ...props }, index) => {
                const logo = localFile.childImageSharp;
                return (
                  <SlideWithQuote logo={logo} logoAltText={logoAltText} {...props} key={index} />
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </section>
  );
};

SliderWithQuote.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.objectOf(PropTypes.any).isRequired,
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      logo: PropTypes.objectOf(PropTypes.any).isRequired,
    })
  ).isRequired,
};

export default SliderWithQuote;
