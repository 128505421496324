import { graphql } from 'gatsby';
import React from 'react';

import Data from 'components/pages/home/data';
import Hero from 'components/pages/home/hero';
import Notifications from 'components/pages/home/notifications';
import Partners from 'components/pages/home/partners';
import PriorityLeads from 'components/pages/home/priority-leads';
import Problem from 'components/pages/home/problem';
import Product from 'components/pages/home/product';
import Recommendation from 'components/pages/home/recommendation';
import Sales from 'components/pages/home/sales';
import SliderWithQuote from 'components/pages/home/slider-with-quote';
import UserBehaviour from 'components/pages/home/user-behaviour';
import BookADemo from 'components/shared/book-a-demo';
import MainLayout from 'layouts/main';

const Home = ({
  data: {
    wpPage: { seo, acf: data },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <Hero {...data.homeHero} />
    <Partners {...data.partners} />
    <Problem {...data.problem} />
    <PriorityLeads {...data.priorityLeads} />
    <Recommendation {...data.recommendation} />
    <Notifications {...data.notifications} />
    <Product {...data.product} />
    <UserBehaviour {...data.userBehaviour} />
    <Data {...data.data} />
    <SliderWithQuote items={data.sliderWithQuote} />
    <Sales {...data.sales} />
    <BookADemo />
  </MainLayout>
);

export default Home;

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        homeHero {
          title
          description
          inputButtonText
        }
        partners {
          title
          logos {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 80)
              }
            }
          }
        }
        problem {
          label
          title
          items {
            iconName
            title
            description
          }
        }
        priorityLeads {
          title
          description
          button {
            title
            url
          }
        }
        recommendation {
          title
          description
        }
        notifications {
          title
          description
        }
        product {
          title
          description
          button {
            title
            url
          }
          link {
            title
            url
          }
        }
        data {
          title
          description
        }
        sliderWithQuote {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 420)
              }
            }
          }
          text
          author
          position
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 26)
              }
            }
          }
        }
        userBehaviour {
          title
          description
        }
        sales {
          title
          items {
            imageName
            title
            description
            label
          }
        }
      }
      ...wpPageSeo
    }
  }
`;
