import classNames from 'classnames';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';
import { MOTION_EASY } from 'constants/constants';

// Wrapper
const wrapperClassNames = classNames(
  /* base */ 'flex flex-col bg-secondary-1 rounded-3xl p-5 pb-3 pr-7',
  /* xl-size */ 'xl:rounded-4xl xl:p-7 xl:pb-2',
  /* lg-size */ 'lg:pr-5',
  /* xs-size */ 'xs:flex-row'
);

// Content
const contentClassNames = classNames(
  /* base */ 'flex items-center justify-center flex-shrink-0 mr-4 h-[82px] w-[82px] border-[3px] border-primary-1 rounded-full',
  /* xl-size */ 'xl:mr-8 xl:h-32 xl:w-32',
  /* lg-size */ 'lg:border-4'
);

// Image
const imageClassNames = classNames(/* base */ 'h-[62px] w-[62px]', /* xl-size */ 'xl:h-24 xl:w-24');

// Description
const descriptionClassNames = classNames(
  /* base */ 'text-sm text-white mt-1',
  /* xl-size */ 'xl:text-base xl:mt-2.5'
);

// Label
const labelClassNames = classNames(
  /* base */ 'block font-bold text-primary-1 mt-4 text-right font-cursive text-xl transform -rotate-6',
  /* xl-size */ 'xl:mt-8 xl:text-2xl',
  /* xs-size */ 'xs:mt-6'
);

const Item = ({ image, title, description, label, animationDelay }) => {
  const variantsAction = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: animationDelay, ease: MOTION_EASY },
    },
  };

  return (
    <motion.div className={wrapperClassNames} variants={variantsAction}>
      <div className={contentClassNames}>
        <GatsbyImage
          className={imageClassNames}
          imgClassName="rounded-full"
          image={getImage(image)}
          alt={title}
        />
      </div>
      <div className="mt-4 xs:mt-0">
        <Heading className="font-extrabold" tag="h3" size="md" theme="white">
          {title}
        </Heading>
        <p className={descriptionClassNames}>{description}</p>
        <span className={labelClassNames}>{label}</span>
      </div>
    </motion.div>
  );
};

Item.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Item;
