import classNames from 'classnames';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { MOTION_EASY } from 'constants/constants';

// Content
const contentClassNames = classNames(
  /* base */ 'p-8 px-4 flex flex-col grid-cols-12 gap-x-10 bg-secondary-6 overflow-hidden',
  /* 2xl-size */ '2xl:items-start',
  /* xl-size */ 'xl:p-14 xl:items-center xl:rounded-5xl',
  /* lg-size */ 'lg:grid',
  /* md-size */ 'md:p-9 md:rounded-4xl'
);

// Title
const titleClassNames = classNames(
  /* base */ 'text-center max-w-[545px]',
  /* lg-size */ 'lg:max-w-auto lg:text-left'
);

// Description
const descriptionClassNames = classNames(
  /* base */ 'mt-3 text-base text-center text-white max-w-[625px]',
  /* 2xl-size */ '2xl:text-xxl',
  /* xl-size */ 'xl:mt-5 xl:text-xl',
  /* lg-screen */ 'lg:text-left'
);

// Illustration
const illustrationWrapperClassNames = classNames(
  /* base */ 'flex justify-center mt-10 col-start-6 col-end-13 relative',
  /* lg-size */ 'lg:block lg:mt-0 '
);

const illustrationClassNames = classNames(
  /* base */ 'relative z-10 w-[493px]',
  /* xl-size */ 'xl:-right-2',
  /* lg-screen */ 'lg:w-full'
);

const shape1ClassNames = classNames(
  /* base */ 'hidden absolute w-[72px] h-auto -top-9 right-10',
  /* 2xl-size */ '2xl:w-auto 2xl:right-10 2xl:-top-14',
  /* xl-size */ 'xl:-top-18 xl:right-24',
  /* lg-size */ 'lg:block lg:-top-10 lg:right-16'
);

const shape2ClassNames = classNames(
  /* base */ 'hidden absolute h-9 w-auto -top-9 ml-[-58px] left-1/2 -translate-x-1/2',
  /* xl-size */ 'xl:h-auto xl:-top-14 xl:ml-[-85px]',
  /* lg-size */ 'lg:block'
);

const Notifications = ({ title, description }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  const variantsContentFade = {
    hidden: {
      opacity: 0,
      y: -5,
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom, ease: MOTION_EASY },
    }),
  };

  const variantsAction = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom, ease: MOTION_EASY },
    }),
  };

  const variantsScale = {
    hidden: {
      opacity: 0.1,
      transform: 'scale(0)',
    },
    visible: (custom) => ({
      opacity: 1,
      transform: 'scale(1)',
      transition: {
        delay: custom,
        duration: 0.5,
        ease: MOTION_EASY,
      },
    }),
  };

  const variantsPath = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        delay: 0.4,
        type: 'spring',
        duration: 1,
        ease: MOTION_EASY,
      },
    },
  };
  return (
    <section className="mt-12 md:mt-6 xl:mt-10">
      <Container className="px-0">
        <div className={contentClassNames}>
          <div className="flex flex-col items-center col-start-1 lg:block md:col-end-6">
            <Heading
              className={titleClassNames}
              tag="h2"
              size="lg"
              theme="white"
              fontWeight="extra"
            >
              {title}
            </Heading>
            <p className={descriptionClassNames}>{description}</p>
          </div>
          <motion.div
            className={illustrationWrapperClassNames}
            initial="hidden"
            animate={animationContainerView && 'visible'}
            ref={animationContainer}
          >
            <div className={illustrationClassNames}>
              <motion.svg
                width="100%"
                height="100%"
                viewBox="0 0 770 340"
                fill="none"
                variants={variantsContentFade}
              >
                {animationContainerView && (
                  <>
                    <motion.g variants={variantsScale} custom={0.6}>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M79.4632 230.001C75.9257 230.004 73.0628 232.869 73.0654 236.4C73.0628 239.931 75.9283 242.796 79.4659 242.799H85.8663V236.402C85.869 232.872 83.0034 230.006 79.4632 230.001C79.4659 230.001 79.4659 230.001 79.4632 230.001V230.001ZM79.4632 247.068H62.4005C58.863 247.07 55.9974 249.936 56 253.466C55.9948 256.997 58.8604 259.863 62.3979 259.868H79.4632C83.0008 259.865 85.8663 257 85.8637 253.469C85.8663 249.936 83.0008 247.07 79.4632 247.068V247.068Z"
                        fill="#36C5F0"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M120.002 253.465C120.005 249.934 117.139 247.069 113.602 247.067C110.064 247.069 107.199 249.934 107.201 253.465V259.867H113.602C117.139 259.864 120.005 256.999 120.002 253.465ZM102.937 253.465V236.399C102.94 232.871 100.077 230.005 96.5392 230C93.0016 230.003 90.1361 232.868 90.1387 236.399V253.465C90.1334 256.996 92.999 259.861 96.5366 259.867C100.074 259.864 102.94 256.999 102.937 253.465Z"
                        fill="#2EB67D"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M96.5235 294C100.061 293.997 102.927 291.132 102.924 287.601C102.927 284.071 100.061 281.205 96.5235 281.203H90.123V287.601C90.1204 291.129 92.986 293.995 96.5235 294ZM96.5235 276.931H113.589C117.126 276.928 119.992 274.063 119.989 270.532C119.995 267.001 117.129 264.136 113.592 264.131H96.5262C92.9886 264.133 90.123 266.999 90.1257 270.53C90.123 274.063 92.986 276.928 96.5235 276.931V276.931Z"
                        fill="#ECB22E"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M56 270.531C55.9974 274.062 58.863 276.928 62.4005 276.93C65.938 276.928 68.8036 274.062 68.801 270.531V264.133H62.4005C58.863 264.135 55.9974 267.001 56 270.531ZM73.0654 270.531V287.598C73.0601 291.129 75.9257 293.994 79.4632 293.999C83.0008 293.997 85.8663 291.131 85.8637 287.601V270.537C85.869 267.006 83.0034 264.141 79.4659 264.135C75.9257 264.135 73.0627 267.001 73.0654 270.531C73.0654 270.534 73.0654 270.531 73.0654 270.531Z"
                        fill="#E01E5A"
                      />
                    </motion.g>
                    <motion.g variants={variantsAction} custom={0.4}>
                      <rect width="593" height="156" rx="12" fill="white" />
                      <rect x="542" y="53" width="20" height="24" fill="url(#paint0_linear)" />
                      <path
                        d="M543 53V77M542 54H562M542 55H562M542 56H562M542 57H562M542 58H562M542 59H562M542 60H562M542 61H562M542 62H562M542 63H562M542 64H562M542 65H562M542 66H562M542 67H562M542 68H562M542 69H562M542 70H562M542 71H562M542 72H562M542 73H562M542 74H562M542 75H562M542 76H562M544 53V77M545 53V77M546 53V77M547 53V77M548 53V77M549 53V77M550 53V77M551 53V77M552 53V77M553 53V77M554 53V77M555 53V77M556 53V77M557 53V77M558 53V77M559 53V77M560 53V77M561 53V77"
                        stroke="url(#paint1_linear)"
                        strokeWidth="0.2"
                      />
                      <mask
                        id="mask0"
                        style={{ maskType: 'alpha' }}
                        maskUnits="userSpaceOnUse"
                        x="543"
                        y="53"
                        width="18"
                        height="22"
                      >
                        <rect
                          x="543.895"
                          y="53.8984"
                          width="16.2054"
                          height="21.1016"
                          fill="#C4C4C4"
                        />
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          d="M542.795 75.0085L547 68.5L554.501 71L561.513 53.7286"
                          stroke="#B80100"
                          strokeWidth="1.6"
                          strokeMiterlimit="2.9238"
                        />
                      </g>

                      <rect
                        opacity="0.25"
                        x="81"
                        y="93"
                        width="172"
                        height="34"
                        rx="5"
                        stroke="#478465"
                        strokeWidth="2"
                      />
                      <text
                        fill="#478465"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="92" y="114.976">
                          Schedule a sales call
                        </tspan>
                      </text>
                      <rect
                        opacity="0.15"
                        x="267"
                        y="94"
                        width="141"
                        height="34"
                        rx="5"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <text
                        fill="black"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="278" y="115.976">
                          Remind me later
                        </tspan>
                      </text>
                      <rect
                        opacity="0.25"
                        x="422"
                        y="94"
                        width="69"
                        height="34"
                        rx="3"
                        stroke="#BF4055"
                        strokeWidth="2"
                      />
                      <text
                        fill="#BF4055"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="433" y="115.976">
                          Ignore
                        </tspan>
                      </text>
                      <text
                        fill="black"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="20"
                        letterSpacing="0em"
                      >
                        <tspan x="80" y="72.22">
                          Acme Ltd has added 5 new users in the last 2 days!
                        </tspan>
                      </text>
                      <text
                        fill="black"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="20"
                        fontWeight="800"
                        letterSpacing="0em"
                      >
                        <tspan x="80" y="44.22">
                          HeadsUp
                        </tspan>
                      </text>
                      <text
                        fill="#81828B"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        letterSpacing="0em"
                      >
                        <tspan x="176" y="43.976">
                          12:45 AM
                        </tspan>
                      </text>
                      <rect x="16" y="28" width="48" height="48" rx="8" fill="black" />
                      <path
                        d="M26.0002 65C25.9388 65 39.9384 37.1139 40 37.0004C40.0614 36.8869 54.0614 65 53.9998 65C53.9529 65 45.7651 64.0104 42 63.5232V59.4833H45.6831C45.6831 59.4833 40.0249 46.9588 40 47.0002C39.975 47.0417 34.5692 59.4833 34.5942 59.4833H38V63.5232C33.9591 64.024 26.0459 65 26.0002 65Z"
                        fill="#FF3333"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="542"
                          y1="53"
                          x2="555"
                          y2="76"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FFFDFD" />
                          <stop offset="1" stopColor="#EAE7E7" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear"
                          x1="543.144"
                          y1="53.7969"
                          x2="560.988"
                          y2="76.1573"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#A7C9E6" />
                          <stop offset="1" stopColor="#B9C4CE" />
                        </linearGradient>
                      </defs>
                    </motion.g>
                    <motion.g variants={variantsAction} custom={0.8}>
                      <rect
                        opacity="0.1"
                        x="177"
                        y="184"
                        width="593"
                        height="156"
                        rx="12"
                        fill="white"
                      />
                      <rect
                        opacity="0.15"
                        x="258"
                        y="277"
                        width="172"
                        height="34"
                        rx="5"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <rect
                        opacity="0.2"
                        x="273"
                        y="290"
                        width="142"
                        height="8"
                        rx="4"
                        fill="white"
                      />
                      <rect
                        opacity="0.2"
                        x="458"
                        y="290"
                        width="111"
                        height="8"
                        rx="4"
                        fill="white"
                      />
                      <rect
                        opacity="0.15"
                        x="444"
                        y="277"
                        width="141"
                        height="34"
                        rx="5"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <rect
                        opacity="0.2"
                        x="613"
                        y="290"
                        width="39"
                        height="8"
                        rx="4"
                        fill="white"
                      />
                      <rect
                        opacity="0.15"
                        x="599"
                        y="277"
                        width="69"
                        height="34"
                        rx="3"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <text
                        opacity="0.4"
                        fill="white"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="20"
                        fontWeight="800"
                        letterSpacing="0em"
                      >
                        <tspan x="257" y="228.22">
                          HeadsUp
                        </tspan>
                      </text>
                      <rect
                        opacity="0.2"
                        x="257"
                        y="242"
                        width="130"
                        height="12"
                        rx="6"
                        fill="white"
                      />
                      <rect
                        opacity="0.2"
                        x="395"
                        y="242"
                        width="76"
                        height="12"
                        rx="6"
                        fill="white"
                      />
                      <rect
                        opacity="0.2"
                        x="479"
                        y="242"
                        width="46"
                        height="12"
                        rx="6"
                        fill="white"
                      />
                      <rect
                        opacity="0.2"
                        x="533"
                        y="242"
                        width="115"
                        height="12"
                        rx="6"
                        fill="white"
                      />
                      <rect
                        opacity="0.2"
                        x="656"
                        y="242"
                        width="60"
                        height="12"
                        rx="6"
                        fill="white"
                      />
                      <text
                        opacity="0.3"
                        fill="white"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        letterSpacing="0em"
                      >
                        <tspan x="353" y="227.976">
                          12:00 AM
                        </tspan>
                      </text>
                      <rect
                        opacity="0.1"
                        x="193"
                        y="212"
                        width="48"
                        height="48"
                        rx="8"
                        fill="white"
                      />
                      <path
                        opacity="0.3"
                        d="M203 249C202.939 249 216.938 221.114 217 221C217.061 220.887 231.061 249 231 249C230.953 249 222.765 248.01 219 247.523V243.483H222.683C222.683 243.483 217.025 230.959 217 231C216.975 231.042 211.569 243.483 211.594 243.483H215V247.523C210.959 248.024 203.046 249 203 249Z"
                        fill="white"
                      />
                    </motion.g>
                  </>
                )}
              </motion.svg>
            </div>

            <div className={shape1ClassNames}>
              <svg
                className="w-full h-full xl:w-[155px] xl:h-[137px]"
                viewBox="0 0 115 137"
                fill="none"
              >
                <motion.path
                  d="M0 134H42C80.6599 134 112 102.66 112 64V0"
                  stroke="#FFFF00"
                  strokeWidth="6"
                  variants={variantsPath}
                  style={{ pathLength, opacity }}
                />
              </svg>
            </div>
            <motion.div className={shape2ClassNames}>
              <svg
                className="w-full h-full xl:w-[38px] xl:h-[56px]"
                viewBox="0 0 38 56"
                fill="none"
              >
                <motion.path
                  d="M3 0V56"
                  stroke="#FFFF00"
                  strokeWidth="6"
                  variants={variantsPath}
                  style={{ pathLength, opacity }}
                />
                <motion.path
                  d="M35 0V56"
                  stroke="#FFFF00"
                  strokeWidth="6"
                  variants={variantsPath}
                  style={{ pathLength, opacity }}
                />
              </svg>
            </motion.div>
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

Notifications.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Notifications;
