import classNames from 'classnames';
import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import LogoIcon from './images/logo.inline.svg';
import Item from './item';

// Title
const titleClassNames = classNames(
  /* base */ 'uppercase max-w-[450px] mark-color-primary-1 leading-none',
  /* 2xl-size */ '2xl:max-w-[810px]',
  /* xl-size */ 'xl:max-w-[600px]',
  /* lg-size */ 'lg:max-w-[540px]'
);

// Logo
const logoClassNames = classNames(
  /* base */ 'mr-1.5 inline-flex max-w-[36px] h-auto',
  /* 2xl-size */ '2xl:max-w-auto',
  /* xl-size */ 'xl:max-w-[73px]',
  /* md-size */ 'md:max-w-[43px] md:mr-2.5'
);

const Sales = ({ title, items }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  const { salesLeaders, revenueOps, accountExecutives, growth } = useStaticQuery(graphql`
    query {
      salesLeaders: file(relativePath: { eq: "pages/home/sales/sales-leaders.png" }) {
        childImageSharp {
          gatsbyImageData(width: 96)
        }
      }

      revenueOps: file(relativePath: { eq: "pages/home/sales/revenue-ops.png" }) {
        childImageSharp {
          gatsbyImageData(width: 96)
        }
      }

      accountExecutives: file(relativePath: { eq: "pages/home/sales/account-executives.png" }) {
        childImageSharp {
          gatsbyImageData(width: 96)
        }
      }

      growth: file(relativePath: { eq: "pages/home/sales/growth.png" }) {
        childImageSharp {
          gatsbyImageData(width: 96)
        }
      }
    }
  `);

  const leftColElements = items.slice(0, 2);
  const rightColElements = items.slice(-2);
  const imageCollection = { salesLeaders, revenueOps, accountExecutives, growth };

  return (
    <section className="mt-16 md:mt-24 xl:mt-40">
      <Container>
        <div className="grid grid-cols-12 md:gap-x-10">
          <div className="col-start-1 col-end-13 lg:col-start-2 lg:col-end-12">
            <Heading className={titleClassNames} tag="h2" size="xl">
              <LogoIcon className={logoClassNames} />
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Heading>

            <motion.div
              className="grid grid-cols-1 mt-8 md:grid-cols-2 gap-x-5 lg:gap-x-7 xl:gap-x-11 xl:mt-14"
              initial="hidden"
              animate={animationContainerView && 'visible'}
              ref={animationContainer}
            >
              <div className="grid gap-y-5 lg:gap-y-7 xl:gap-y-11 md:hidden">
                {leftColElements
                  .concat(rightColElements)
                  .map(({ imageName, title, ...props }, index) => {
                    const image = imageCollection[imageName];
                    const animationDelay = [0, 0.2, 0.4, 0.6];
                    return (
                      <Item
                        image={image}
                        title={title}
                        {...props}
                        key={title}
                        animationDelay={animationDelay[index]}
                      />
                    );
                  })}
              </div>

              <div className="hidden md:grid gap-y-5 lg:gap-y-7 xl:gap-y-11">
                {leftColElements.map(({ imageName, title, ...props }, index) => {
                  const image = imageCollection[imageName];
                  const animationDelay = [0, 0.4];
                  return (
                    <Item
                      image={image}
                      title={title}
                      {...props}
                      key={title}
                      animationDelay={animationDelay[index]}
                    />
                  );
                })}
              </div>

              <div className="hidden md:grid mt-5 gap-y-5 lg:gap-y-7 xl:gap-y-11 lg:mt-0 md:translate-y-4 lg:translate-y-16 xl:translate-y-20">
                {rightColElements.map(({ imageName, title, ...props }, index) => {
                  const image = imageCollection[imageName];
                  const animationDelay = [0.2, 0.6];
                  return (
                    <Item
                      image={image}
                      title={title}
                      {...props}
                      key={title}
                      animationDelay={animationDelay[index]}
                    />
                  );
                })}
              </div>
            </motion.div>
          </div>
        </div>
      </Container>
    </section>
  );
};

Sales.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Sales;
