import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

// Title
const titleClassNames = classNames('uppercase text-center font-extrabold');

// Logos
const logosWrapperClassNames = classNames(
  /* base */ 'grid grid-cols-2 gap-x-4 max-w-[890px] mx-auto mt-8',
  /* xl-size */ 'xl:max-w-[1120px] xl:mt-14',
  /* sm-size */ 'sm:flex sm:flex-wrap sm:justify-center sm:gap-x-0'
);

const logoClassNames = classNames(
  /* base */ 'flex justify-center items-center h-[52px] bg-secondary-4 rounded-8xl mb-4 last:mb-0',
  /* xl-size */ 'xl:mx-4 xl:mb-8 xl:h-20 xl:w-[248px]',
  /* lg-size */ 'lg:mx-3',
  /* sm-size */ 'sm:mx-2.5 sm:w-[158px] sm:mb-6'
);

const Partners = ({ title, logos }) => {
  const controls = useAnimation();
  const tmp = Array.from(Array(logos.length).keys());
  const shuffleIds = tmp.sort(() => Math.random() - 0.5);
  const [sectionRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        x: 0,
        transition: { delay: shuffleIds[i] * 0.1 },
      }));
    }
  }, [inView, controls, shuffleIds]);
  return (
    <section className="mt-14 xl:mt-20">
      <Container>
        <Heading className={titleClassNames} tag="h2" size="xs">
          {title}
        </Heading>
        <div className={logosWrapperClassNames} ref={sectionRef}>
          {logos.map(({ localFile, altText }, index) => {
            const image = localFile.childImageSharp;
            return (
              <motion.div
                animate={controls}
                className={logoClassNames}
                key={index}
                initial={{ opacity: 0, x: -20 }}
                custom={index}
              >
                <GatsbyImage image={getImage(image)} alt={altText} />
              </motion.div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

Partners.propTypes = {
  title: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Partners;
