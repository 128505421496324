import classNames from 'classnames';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { MOTION_EASY } from 'constants/constants';

// Content
const contentClassNames = classNames(
  /* base */ 'relative p-8 px-4 flex flex-col grid-cols-12 bg-secondary-5 overflow-hidden',
  /* 2xl-size */ '2xl:items-start ',
  /* xl-size */ 'xl:p-14 xl:pb-10 xl:items-center xl:rounded-5xl',
  /* lg-size */ 'lg:grid lg:pb-6',
  /* md-size */ 'md:p-9 md:rounded-4xl'
);

// Title
const titleClassNames = classNames(
  /* base */ 'text-center max-w-[545px]',
  /* lg-size */ 'lg:max-w-full lg:text-left'
);

// Description
const descriptionClassNames = classNames(
  /* base */ 'mt-3 text-base text-center max-w-[625px]',
  /* 2xl-size */ '2xl:text-xxl 2xl:max-w-full',
  /* xl-size */ 'xl:mt-5 xl:text-xl',
  /* lg-screen */ 'lg:max-w-[540px] lg:text-left'
);

// Illustration
const illustrationWrapperClassNames = classNames(
  /* base */ 'flex justify-center mt-12 col-start-8 col-end-13',
  /* lg-size */ 'lg:block lg:mt-0',
  /* md-size */ 'md:mt-6'
);

const illustrationClassNames = classNames(
  /* base */ 'relative z-10 w-[300px] h-auto',
  /* 2xl-size */ '2xl:-top-3 2xl:right-[-72px]',
  /* xl-size */ 'xl:ml-0 xl:w-[471px]',
  /* lg-size */ 'lg:-top-2 lg:right-3 lg:ml-auto'
);

const lineClassNames = classNames(
  /* base */ 'hidden absolute top-0 h-full',
  /* 2xl-size */ '2xl:-right-11',
  /* xl-size */ 'xl:w-auto xl:-right-14',
  /* lg-size */ 'lg:block -right-7'
);

const UserBehaviour = ({ title, description }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const pathLength = useMotionValue(0);
  const pathOpacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  const variantsContentFade = {
    hidden: {
      opacity: 0,
      y: -5,
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom, ease: MOTION_EASY },
    }),
  };

  const variantsAction = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom, ease: MOTION_EASY },
    }),
  };

  const variantsPath = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: (custom) => ({
      pathLength: 1,
      transition: {
        delay: custom,
        type: 'spring',
        duration: 2.5,
        ease: MOTION_EASY,
      },
    }),
  };
  return (
    <section className="mt-12 md:mt-24 xl:mt-40">
      <Container className="px-0">
        <div className={contentClassNames}>
          <div className="flex flex-col items-center col-start-1 lg:block md:col-end-8">
            <Heading className={titleClassNames} tag="h2" size="lg" fontWeight="extra">
              {title}
            </Heading>
            <p className={descriptionClassNames}>{description}</p>
          </div>
          <motion.div
            className={illustrationWrapperClassNames}
            initial="hidden"
            animate={animationContainerView && 'visible'}
            ref={animationContainer}
          >
            <div className={illustrationClassNames}>
              <motion.svg
                width="100%"
                height="100%"
                viewBox="0 0 471 288"
                fill="none"
                variants={variantsContentFade}
              >
                {animationContainerView && (
                  <>
                    <motion.g variants={variantsAction} custom={0.7}>
                      <rect x="44" width="427" height="100" rx="50" fill="#0B1F22" />
                      <text
                        transform="translate(391.214 41.4609) rotate(-8)"
                        fill="#FFFF00"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Kalam"
                        fontSize="30"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="0.458008" y="22.98">
                          Hot!
                        </tspan>
                      </text>
                      <text
                        fill="white"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="20"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="84" y="38.2205">
                          5 log-ins in the past 7 days
                        </tspan>
                      </text>
                      <circle cx="98" cy="64" r="14" fill="white" />
                      <path
                        d="M89.3849 71.5384C89.3471 71.5384 97.9623 54.3777 98.0002 54.3078C98.0379 54.238 106.653 71.5384 106.615 71.5384C106.587 71.5384 101.548 70.9294 99.2309 70.6296V68.1435H101.497C101.497 68.1435 98.0154 60.4361 98.0002 60.4616C97.9848 60.4871 94.6581 68.1435 94.6735 68.1435H96.7694V70.6296C94.2826 70.9378 89.413 71.5384 89.3849 71.5384Z"
                        fill="#FF3333"
                      />
                      <rect x="120" y="50" width="257" height="28" rx="14" fill="#FFFF00" />
                      <text
                        fill="black"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="132" y="68.976">
                          85% correlation with conversion
                        </tspan>
                      </text>
                    </motion.g>
                    <motion.g variants={variantsContentFade} custom={0.5}>
                      <rect y="36.0005" width="28" height="28" rx="4" fill="#0080FF" />
                      <path
                        d="M7 50.6961L11.5294 55.0005L21 46.0005"
                        stroke="white"
                        strokeWidth="3"
                      />
                    </motion.g>
                    <motion.g variants={variantsAction} custom={0.9}>
                      <rect x="81" y="116" width="390" height="100" rx="50" fill="#0B1F22" />
                      <text
                        transform="translate(391.214 157.461) rotate(-8)"
                        fill="#FFFF00"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Kalam"
                        fontSize="30"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="0.458008" y="22.98">
                          Hot!
                        </tspan>
                      </text>
                      <text
                        fill="white"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="20"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="121" y="154.22">
                          &gt; 10 min spent in app
                        </tspan>
                      </text>
                      <circle cx="135" cy="180" r="14" fill="white" />
                      <path
                        d="M126.385 187.538C126.347 187.538 134.962 170.378 135 170.308C135.038 170.238 143.653 187.538 143.615 187.538C143.587 187.538 138.548 186.929 136.231 186.63V184.144H138.497C138.497 184.144 135.015 176.436 135 176.462C134.985 176.487 131.658 184.144 131.673 184.144H133.769V186.63C131.283 186.938 126.413 187.538 126.385 187.538Z"
                        fill="#FF3333"
                      />
                      <rect x="157" y="166" width="220" height="28" rx="14" fill="#FFFF00" />
                      <text
                        fill="black"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="16"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="169" y="184.976">
                          70% correlation with upsell
                        </tspan>
                      </text>
                    </motion.g>
                    <motion.g variants={variantsContentFade} custom={0.7}>
                      <rect x="37" y="152" width="28" height="28" rx="4" fill="#0080FF" />
                      <path d="M44 166.696L48.5294 171L58 162" stroke="white" strokeWidth="3" />
                    </motion.g>
                    <motion.g variants={variantsContentFade} custom={1.2}>
                      <rect x="260" y="232" width="211" height="56" rx="28" fill="#0080FF" />
                      <text
                        fill="white"
                        space="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Figtree"
                        fontSize="20"
                        fontWeight="bold"
                        letterSpacing="0em"
                      >
                        <tspan x="304" y="266.22">
                          Create metric
                        </tspan>
                      </text>
                    </motion.g>
                  </>
                )}
              </motion.svg>
            </div>

            <div className={lineClassNames}>
              <svg className="w-full h-full" viewBox="0 0 571 376" fill="none">
                <motion.path
                  d="M3 0V232.707C3 263.797 25.9492 289 54.2586 289C82.5679 289 105.517 263.797 105.517 232.707V164C105.517 125.34 134.054 94.0001 169.256 94.0001H491.969H538C554.569 94.0001 568 107.432 568 124V274C568 290.568 554.569 304 538 304H491.969H189.664C154.462 304 125.925 335.34 125.925 374V376"
                  stroke="#FFFF00"
                  strokeWidth="6"
                  variants={variantsPath}
                  style={{ pathLength, opacity: pathOpacity }}
                  custom={0.4}
                />
              </svg>
            </div>
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

UserBehaviour.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default UserBehaviour;
