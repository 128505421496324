import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

// Image
const imageClassNames = classNames(
  /* base */ 'w-full min-h-[200px]',
  /* 2xl-size */ '2xl:h-[420px]',
  /* xl-size */ 'xl:h-[325px]',
  /* lg-size */ 'lg:h-[269px]',
  /* md-size */ 'md:h-[277px]',
  /* xs-size */ 'xs:min-h-[230px]'
);

const SlideWithImage = ({ image, altText }) => (
  <GatsbyImage
    className={imageClassNames}
    imgClassName="rounded-4xl xl:rounded-5xl"
    image={getImage(image)}
    alt={altText}
  />
);

SlideWithImage.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  altText: PropTypes.string.isRequired,
};

export default SlideWithImage;
