import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import animationData from 'animations/home/data/animation.json';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import useLottie from 'hooks/use-lottie';


import illustrationMdScreen from './images/illustration-md.svg';
import illustrationSmScreen from './images/illustration-sm.svg';


// Content
const contentClassNames = classNames(
  /* base */ 'flex flex-col p-8 px-4 bg-secondary-1',
  /* 2xl-size */ '2xl:pb-30',
  /* xl-size */ 'xl:pt-20 xl:pb-20 xl:rounded-5xl',
  /* lg-size */ 'lg:pb-16',
  /* md-size */ 'md:p-12 md:pb-14 md:rounded-4xl'
);

// Description
const descriptionClassNames = classNames(
  /* base */ 'text-white text-center mt-2.5 max-w-[620px] mx-auto font-semibold text-base',
  /* 2xl-size */ '2xl:max-w-[968px] 2xl:text-xxl',
  /* xl-size */ 'xl:mt-5 xl:text-xl'
);

const Data = ({ title, description }) => {
  const [animationVisibilityRef, inView] = useInView({
    threshold: 0.2,
  });

  const [animation, animationRef] = useLottie({
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData,
  });

  useEffect(() => {
    if (!animation) return;
    if (inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, inView]);

  return (
    <section className="mt-12 md:mt-24 xl:mt-40">
      <Container className="px-0">
        <div className={contentClassNames}>
          <Heading className="tracking-wide text-center uppercase" tag="h2" size="lg" theme="white">
            {title}
          </Heading>
          <p className={descriptionClassNames}>{description}</p>
          <div className="mt-9 md:mt-4 lg:mt-0" ref={animationVisibilityRef}>
            <div className="w-full hidden lg:block" ref={animationRef} />
            <img
              className="hidden mx-auto md:block lg:hidden"
              src={illustrationMdScreen}
              alt=""
              loading="lazy"
            />
            <img className="mx-auto md:hidden" src={illustrationSmScreen} alt="" loading="lazy" />
          </div>
        </div>
      </Container>
    </section>
  );
};

Data.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Data;
