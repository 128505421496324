import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import EmailInputForm from 'components/shared/email-input-form';
import Heading from 'components/shared/heading';
import { gtagBookDemoSubmissionSuccessEvent } from 'constants/constants';

import illustration from './images/illustration.svg';

const FORM_ID = 1;

// Content
const contentClassNames = classNames(
  /* base */ 'p-8 px-4 pb-10 flex flex-col items-center justify-between bg-secondary-1',
  /* 2xl-size */ '2xl:p-32',
  /* xl-size */ 'xl:rounded-5xl xl:!px-20',
  /* lg-size */ 'lg:p-20 lg:pr-12 lg:flex-row',
  /* md-size */ 'md:rounded-4xl'
);

// Title
const titleClassNames = classNames(
  /* 2xl-size */ 'uppercase mx-auto text-center max-w-[458px] !leading-none',
  /* xl-size */ 'xl:max-w-[675px]',
  /* lg-size */ 'lg:text-left lg:mx-0 lg:max-w-[500px]'
);

// Description
const descriptionClassNames = classNames(
  /* base */ 'mt-4 text-lg text-center mx-auto leading-snug text-white font-bold max-w-[577px]',
  /* 2xl-size */ '2xl:text-3xl',
  /* xl-size */ 'xl:text-xxl xl:max-w-[595px]',
  /* lg-size */ 'lg:text-left lg:mx-0 lg:text-xl lg:max-w-[420px] lg:mt-6'
);

// Email Input Form
const emailInputFormClassNames = classNames(
  /* base */ 'mx-auto mt-6 max-w-[420px] book-demo-form',
  /* xl-size */ 'xl:max-w-[590px] xl:mt-10',
  /* lg-size */ 'lg:mt-7 lg:mx-0'
);

// Illustration
const illustrationWrapperClassNames = classNames(
  /* base */ 'hidden items-center mt-15',
  /* lg-size */ 'lg:mt-0',
  /* xs-size */ 'xs:flex'
);

const illustrationClassNames = classNames(
  /* base */ 'mx-auto',
  /* 2xl-size */ '2xl:max-w-full',
  /* xl-size */ 'xl:max-w-[420px]',
  /* lg-size */ 'lg:mx-0 lg:max-w-[400px]',
  /* md-size */ 'md:w-full',
  /* sm-size */ 'sm:max-w-[390px]'
);

const Hero = ({ title, description, inputButtonText: buttonText }) => (
  <section className="xl:mt-2 header-offset">
    <Container className="px-0">
      <div className={contentClassNames}>
        <div>
          <Heading className={titleClassNames} tag="h1" size="2xl" theme="white" asHTML>
            {title}
          </Heading>
          <p className={descriptionClassNames}>{description}</p>
          <EmailInputForm
            className={emailInputFormClassNames}
            formName="early-access"
            buttonText={buttonText}
            formId={FORM_ID}
            gtagSuccessEvent={gtagBookDemoSubmissionSuccessEvent}
            backgroundColorInput="yellow"
          />
        </div>
        <div className={illustrationWrapperClassNames}>
          <img className={illustrationClassNames} src={illustration} alt="" />
        </div>
      </div>
    </Container>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  inputButtonText: PropTypes.string.isRequired,
};

export default Hero;
