import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import animationDataArrow from 'animations/home/problem/arrow.json';
import animationDataClose from 'animations/home/problem/close.json';
import animationDataUser from 'animations/home/problem/user.json';
import Heading from 'components/shared/heading';
import useLottie from 'hooks/use-lottie';



const animationCollection = {
  user: animationDataUser,
  close: animationDataClose,
  arrow: animationDataArrow,
};

const itemClassNames = classNames(
  /* base */ 'flex flex-col justify-between bg-secondary-1 rounded-3xl p-5 flex-shrink-0 flex-grow-0 max-w-[219px]',
  /* xl-size */ 'xl:p-8 xl:pr-7 xl:pb-16 xl:rounded-4xl',
  /* lg-size */ 'lg:pb-9',
  /* md-size */ 'md:max-w-max'
);

const Item = ({ iconName, title, description }) => {
  const [animationVisibilityRef, inView] = useInView({
    threshold: 0.2,
  });

  const animationData = animationCollection[iconName];

  const [animation, animationRef] = useLottie({
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData,
  });

  useEffect(() => {
    if (!animation) return;
    if (inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, inView]);

  return (
    <div className={itemClassNames} ref={animationVisibilityRef}>
      <div>
        <div
          className="w-[50px] h-[50px] ml-[-5px] xl:w-20 xl:h-20 xl:-ml-2.5"
          ref={animationRef}
        />
        <Heading
          className="mt-3.5 xl:mt-5 font-extrabold leading-6 xl:!leading-10"
          tag="h3"
          size="md"
          theme="white"
        >
          {title}
        </Heading>
        <p className="mt-3 text-sm leading-snug text-white xl:mt-4 xl:text-lg">{description}</p>
      </div>
    </div>
  );
};

export default Item;
