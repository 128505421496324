import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import Item from './item';

// Wrapper
const wrapperClassNames = classNames('mt-10 bg-secondary-10', 'xl:mt-14');
// Content
const contentClassNames = classNames(
  /* base */ 'py-14 flex flex-col grid-cols-12 gap-x-10',
  /* 2xl-size */ '2xl:grid',
  /* xl-size */ 'xl:py-20'
);
// Label
const labelClassNames = classNames(
  /* base */ 'font-semibold text-sm',
  /* 2xl-size */ '2xl:text-xxl',
  /* xl-size */ 'xl:text-xl',
  /* lg-size */ 'lg:text-base'
);
// Title
const titleClassNames = classNames(
  'mt-2.5 uppercase !leading-none flat-breaks',
  'xl:mt-5',
  'sm:flat-none'
);
// Items
const itemsWrapperClassNames = classNames(
  /* base */ 'flex space-x-4 mt-7 overflow-x-auto -mx-4 after:w-4 after:flex-shrink-0 after:flex-grow-0 before:w-4 before:flex-shrink-0 before:flex-grow-0',
  /* xl-size */ 'xl:mt-16 xl:gap-x-11',
  /* lg-size */ 'lg:mt-10 lg:gap-x-6',
  /* md-size */ 'md:grid-cols-3 md:gap-y-0 md:gap-x-5 md:mx-0 md:grid md:space-x-0 md:overflow-x-hidden md:before:hidden md:after:hidden'
);

const Problem = ({ label, title, items }) => (
    <section className={wrapperClassNames}>
      <Container className={contentClassNames}>
        <div className="col-start-2 col-end-12">
          <span className={labelClassNames}>{label}</span>
          <Heading className={titleClassNames} tag="h2" size="xl" asHTML>
            {title}
          </Heading>
          <div className={itemsWrapperClassNames}>
            {items.map((props, index) => (
              <Item {...props} key={index} />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );

Problem.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Problem;
