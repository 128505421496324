import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import illustration from './images/illustration.svg';

// Wrapper
const wrapperClassNames = classNames(
  /* base */ 'mt-12 bg-gray-1 py-8',
  /* xl-size */ 'xl:mt-40 xl:py-24',
  /* md-size */ 'md:mt-24 md:py-16'
);

// Content
const contentClassNames = classNames(
  /* base */ 'flex flex-col grid-cols-12 gap-x-8 items-center',
  /* xl-size */ 'xl:gap-x-10',
  /* lg-size */ 'lg:grid'
);

// Title
const titleClassNames = classNames(
  /* base */ 'uppercase text-center tracking-wide max-w-[545px]',
  /* lg-size */ 'lg:max-w-auto lg:text-left'
);

// Description
const descriptionClassNames = classNames(
  /* base */ 'mt-5 text-base text-center max-w-[625px]',
  /* 2xl-size */ '2xl:text-xxl',
  /* xl-size */ 'xl:mt-7 xl:text-xl',
  /* lg-size */ 'lg:text-left lg:max-w-auto'
);

// Illustration
const illustrationWrapperClassNames = classNames(
  /* base */ 'flex justify-center mt-10 order-2 col-start-1',
  /* lg-size */ 'lg:block lg:mt-0 lg:order-none lg:col-end-8'
);

const illustrationClassNames = classNames(
  /* base */ 'rounded-3xl w-[510px]',
  /* xl-size */ 'xl:rounded-4xl xl:w-full'
);

// Link
const linkClassNames = classNames(
  /* base */ 'transition-colors duration-200 hover:text-black font-bold text-primary-2 ml-5 text-sm',
  /* xl-size */ 'xl:ml-8 xl:text-xl'
);

const Product = ({ title, description, button: { title: buttonText, url: buttonUrl }, link }) => (
  <section className={wrapperClassNames}>
    <Container className="flex flex-col lg:flex-row">
      <div className={contentClassNames}>
        <div className={illustrationWrapperClassNames}>
          <img className={illustrationClassNames} src={illustration} alt="" loading="lazy" />
        </div>
        <div className="flex flex-col items-center lg:block order-1 lg:order-none col-start-1 col-end-13 lg:col-start-8">
          <Heading className={titleClassNames} tag="h2" size="lg">
            {title}
          </Heading>
          <p className={descriptionClassNames}>{description}</p>
          <div className="mt-6 xl:mt-10">
            <Button
              className="py-3 px-3.5 xl:py-6 xl:px-8"
              to={buttonUrl}
              theme="primary"
              size="md"
            >
              {buttonText}
            </Button>
            {link && (
              <Link to={link.url} className={linkClassNames}>
                {link.title}
              </Link>
            )}
          </div>
        </div>
      </div>
    </Container>
  </section>
);

Product.defaultProps = {
  link: null,
};

Product.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default Product;
