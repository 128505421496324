import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import QuoteIcon from 'icons/quote.inline.svg';

// Icon
const iconWrapperClassNames = classNames(
  /* base */ 'hidden items-center justify-center bg-primary-1 h-[52px] w-[52px] rounded-full',
  /* xl-size */ 'xl:h-20 xl:w-20',
  /* lg-size */ 'lg:flex'
);

const iconClassNames = classNames(/* base */ 'w-[26px]', /* xl-size */ 'xl:w-auto');

// Text
const textClassNames = classNames(
  /* base */ 'leading-[1.15] text-2xl font-semibold',
  /* 2xl-size */ '2xl:text-5xl 2xl:leading-[3.375rem]',
  /* xl-size */ 'xl:text-4xl xl:leading-snug'
);

// Image
const imageWrapperClassNames = classNames(
  /* base */ 'flex md:ml-2.5 bg-white rounded-7xl py-2 px-4 max-w-[124px] ',
  /* xl-size */ 'xl:py-2.5 xl:px-8 xl:max-w-full'
);

const SlideWithQuote = ({ text, author, position, logo, logoAltText }) => (
  <figure>
    <div className={iconWrapperClassNames}>
      <QuoteIcon className={iconClassNames} />
    </div>
    <blockquote className="mt-6 xl:mt-8">
      <p className={textClassNames}>{text}</p>
    </blockquote>
    <figcaption className="mt-6 xl:mt-8">
      <div className="flex flex-col space-y-4 xs:space-y-0 xs:space-x-2 xs:flex-row xs:items-center">
        <div className="text-xs xl:text-lg">
          <span className="font-extrabold">{author} – </span>
          <span className="font-semibold">{position}</span>
        </div>

        <div className={imageWrapperClassNames}>
          <GatsbyImage image={getImage(logo)} alt={logoAltText} objectFit="contain" />
        </div>
      </div>
    </figcaption>
  </figure>
);

SlideWithQuote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
  logoAltText: PropTypes.string.isRequired,
};

export default SlideWithQuote;
